(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@carto/react-core"), require("@deck.gl/carto"), require("@deck.gl/extensions"), require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-workers"));
	else if(typeof define === 'function' && define.amd)
		define(["@carto/react-core", "@deck.gl/carto", "@deck.gl/extensions", "react", "react-redux", "@carto/react-redux", "@carto/react-workers"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactApi"] = factory(require("@carto/react-core"), require("@deck.gl/carto"), require("@deck.gl/extensions"), require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-workers"));
	else
		root["cartoReactApi"] = factory(root["@carto/react-core"], root["@deck.gl/carto"], root["@deck.gl/extensions"], root["react"], root["react-redux"], root["@carto/react-redux"], root["@carto/react-workers"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__332__, __WEBPACK_EXTERNAL_MODULE__487__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__756__, __WEBPACK_EXTERNAL_MODULE__347__, __WEBPACK_EXTERNAL_MODULE__116__) {
return 
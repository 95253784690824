(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@material-ui/core/styles"), require("@material-ui/core/styles/createSpacing"), require("react"), require("@material-ui/core"), require("@material-ui/icons"), require("@material-ui/lab"), require("echarts-for-react"));
	else if(typeof define === 'function' && define.amd)
		define(["@material-ui/core/styles", "@material-ui/core/styles/createSpacing", "react", "@material-ui/core", "@material-ui/icons", "@material-ui/lab", "echarts-for-react"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactUi"] = factory(require("@material-ui/core/styles"), require("@material-ui/core/styles/createSpacing"), require("react"), require("@material-ui/core"), require("@material-ui/icons"), require("@material-ui/lab"), require("echarts-for-react"));
	else
		root["cartoReactUi"] = factory(root["@material-ui/core/styles"], root["@material-ui/core/styles/createSpacing"], root["react"], root["@material-ui/core"], root["@material-ui/icons"], root["@material-ui/lab"], root["echarts-for-react"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__793__, __WEBPACK_EXTERNAL_MODULE__294__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__670__, __WEBPACK_EXTERNAL_MODULE__741__, __WEBPACK_EXTERNAL_MODULE__588__, __WEBPACK_EXTERNAL_MODULE__981__) {
return 
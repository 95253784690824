(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@carto/react-core"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@carto/react-core"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactAuth"] = factory(require("react"), require("@carto/react-core"));
	else
		root["cartoReactAuth"] = factory(root["react"], root["@carto/react-core"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__485__) {
return 